html {
    scroll-behavior: smooth;
}

#root{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

}

body {
    font-family: 'Open Sans', sans-serif;
    padding: 2%;
    margin: 0;
    background-color: #292C42;
    color: #F2F2F2;
}

.App{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.App-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.hello {
    transition: 5s;
}

h1 {
    margin-top: 0;
    margin-bottom: 50px;
    font-size: 3.2rem;
}

h4{
    font-size: 1.5rem;
}

p {
    max-width: 1000px;
}

button {
    padding: 1rem 2rem;
    margin: 1rem;
    background-color: #1B9688;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 2.2rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

button:hover {
    background-color: #296248;
}

.image_1 {
    background-image: url("../public/images/not_safe.png");
    width: 980px;
    height: 140px;
}

.image_2 {
    background-image: url("../public/images/safe.png");
    width: 980px;
    height: 120px;
}

div {
    display: flex;
    align-items: center;
    font-size: 2.2rem;
}

#copyNotification {
    display: none;
    position: fixed;
    top: 22%;
    left: 50%;
    transform: translate(-50%, +50%);
    background-color: #296248;
    color: #D5F0D9;
    padding: 20px;
    border-radius: 5px;
    z-index: 9999;
    font-size: 22px;
}

#copy_button {
    margin-top: 13px;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
}

#toggleButton {
    border: none;
    padding: 10px 20px;
    font-size: 1.5rem;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

#textToCopy {
    border: 1px solid black;
    padding: 0 1rem;
    margin-left: 1rem;
}

.password{
    flex-direction: row;
}

.main_content {

    width: 700px;
    background-color: #F2F2F2;
    color: black;
    padding: 1rem 4rem;
    margin: 2rem;
    border-radius: 10px;
    text-align: start;
}

.main_content div{
        flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 500px) {
    html, body {
        max-width: 100%;
        overflow-x: hidden;
    }

    body {
        padding: 5%;
    }

    h1 {
        font-size: 2.2rem;
        margin-bottom: 1rem;
    }
    h4{
        font-size: 1.2rem
    }

    p {
        max-width: 100%;
        font-size: 0.8rem;
    }

    button {
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
    }

    div {
        font-size: 0.9rem;
    }

    #copyNotification {
        top: 10%;
        font-size: 1rem;
        padding: 0.5rem;
    }

    #copy_button {
        font-size: 1rem;
        margin-bottom: 1rem;
        padding: 0.5rem 1rem;
    }

    #toggleButton {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    #textToCopy {
        padding: 0 0.25rem;
        margin-left: 0.25rem;
    }

    .main_content {
        width: 90%;
        padding: 1rem;
        margin: 0.5rem 0;
    }

    .main_content p {
        font-size: 1.2rem;
    }

    .image_1 {
        background-image: url("../public/images/not_safe_phone.png");
        width: 300px;
        height: 81px;
        background-size: cover;
    }

    .image_2 {
        background-image: url("../public/images/safe_phone.png");
        width: 300px;
        height: 71px;
        background-size: cover;
    }
}


