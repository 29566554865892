#submenu {
    margin-top: 2rem;
    padding: 1rem;
    border-top: 1px solid #F2F2F2;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 1.5s ease, height 1.5s ease;
    flex-direction: column;
    align-items: flex-start;
    max-width: 950px;
    font-size: 1rem;
    text-align: left;
    line-height: 1.5rem;
}

#submenu.open {
    opacity: 1;
    height: auto;
}

#submenu p {
    text-indent: 2rem;
}

.button_box{
    display: block;
    justify-content: center;
}

#toggleButton{
    margin: 0 auto;
}


